.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience__container > div {
  background-color: var(--color-bg-variant);
  padding: 2.4 rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.experience_details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experience__frontend {
  width: 98%;
  height: 98%;
}
.experience__frontend h3 {
  text-align: center;
}

.experience__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2%;
}

/* ==================== Media Queries (Medium Devices) ========================*/

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    padding: 1rem;
  }
}

/* ==================== Media Queries (Small Devices) ========================*/

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
    text-align: center;
  }

  .experience__details {
    display: grid;
    grid-template-columns: 2, 2fr;
    justify-items: stretch;
    height: auto;
    width: auto;
  }
  .experience__details div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .experience__content {
    display: grid;
  }
}
